import { PageElement, html, css } from 'Elements';
import { Fetcher, UrlParams, Lang, Sleep } from 'Utils';
import merge from 'deepmerge';

class TableBasic extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding-top:20px;
        }

        .loader_container {
          height:55px;
          align-content:center;
        }

        m-icon {
          cursor:pointer;
          font-size:0.9em;
        }

        .itemTitle {
          padding-left:5px;
          padding-right:5px;
          font-weight:bold;
        }

        table {
          width:100%;
          border-collapse: collapse;
          font-size:0.9em;
          text-align:left;
          margin-left:auto;
          margin-right:auto;
          box-sizing: border-box;
        }

        table.disabled {
          pointer-events:none;
          filter: grayscale(100%);
          opacity:0.5;
        }

        table thead {
          font-weight:bold;
          height:40px;
          align-items:center;
        }

        table th {
          border: 1px solid var(--sl-color-neutral-200);
          padding-left: 10px;
        }

        table tr {
          cursor:pointer;
          box-sizing: border-box;
          border-radius:5px;
        }

        table td {
          padding: 7px;
          border:1px solid var(--sl-color-neutral-200);
        }

        table td.icon {
          width:30px;
          text-align:center;
          font-size:1.8em;
          font-weight:bold;
          padding:0;
        }

        table td.icon m-icon {
          opacity:0.7;
          transition: none;
        }

        table tbody tr:nth-child(even) td {
          
        }

        table tbody tr:nth-child(odd) td {
          background-color: var(--table-list-odd-background-color)
        }

        table tbody tr:hover {
          outline:2px ridge var(--sl-color-primary-200);
          outline-offset: -1px;
        }

        table tbody tr.selected {
          outline:2px ridge var(--sl-color-primary-500);
          outline-offset: -1px;
        }

        table tbody tr:hover td.icon m-icon {
          opacity:1;
        }

        .center {
          text-align:center;
        }

        sl-dropdown {
          text-align:left;
          height: 22px;
          overflow: hidden;
        }

        sl-menu-item m-icon {
          font-size:1.5em;
        }

        sl-menu-item m-icon[name="delete"] {
          color:var(--sl-color-danger-500);
        }

        sl-menu-item::part(base) {
          font-size:0.85em;
          padding:2px;
          padding-left:5px;
        }

        sl-menu-item::part(checked-icon) {
          display:none;
        }

      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            add:'Add',
            refresh:'Refresh',
            cancel:'Cancel',
            delete:'Delete permanently',
            empty:'No item available',
            product:'product',
            modify:'Modify',
            remove:'Delete',
            remove_confirm:'Are you sure you want to delete',
            duplicate:'Duplicate',
            duplicate_new_name:'New name',
            copy_of:'Copy of'
          },
        },
        french:{
          translation: {
            add:'Ajouter',
            refresh:'Rafraîchir',
            cancel:'Annuler',
            delete:'Supprimer définitivement',
            empty:'Aucun élement disponible',
            product:'produit',
            modify:'Modifier',
            remove:'Supprimer',
            remove_confirm:'Êtes-vous sûr de vouloir supprimer',
            duplicate:'Dupliquer',
            copy_of:'Copie de',
            duplicate_new_name:'Nouveau nom',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.loading = true;
    this.apiEndpoint = null; // private/admin/items
    this.urlVar = 'id';
    this.addDisabled = false;
    this.itemModel = {};
    this.itemTitleField = 'title';
    this.eventUpdated = 'item-updated';
    this.items = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadItems = this.loadItems.bind(this);
    window.addEventListener(this.eventUpdated, this.loadItems);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener(this.eventUpdated, this.loadItems);
  }

  _sortItems(items) {
    // override me
    return items;
  }

  async loadItems(ev) {
    this.loading = true;
    if (!this.apiEndpoint) {
      throw new Error(`${this.constructor.name}: you MUST set property apiEndpoint  (ex: api/items)`);
    }
    const response = await Fetcher.get(this.apiEndpoint);
    const items = response?.data;
    this.items = this._sortItems(items);
    this.loading = false;

    const id = ev?.detail?._id || ev?.detail?.id;
    if (id) {
      const selectId = id;
      setTimeout(() => {
        this._selectItem(selectId);
      }, 1);
    }
  }

  async updated(changedProperties) {
    if (changedProperties.has('loading')) {
      const table = this.shadowRoot.querySelector('table');
      if (table) {
        table.classList.toggle('disabled', this.loading);
      }
    }
  }

  async visibleCallback() {
    super.visibleCallback();

    this.modalItemEdit = this.shadowRoot.querySelector('#modal-edit');
    this.modalItemDelete = this.shadowRoot.querySelector('#modal-delete');


    if (ENV === 'dev') {
      if (!this.modalItemEdit) {
        console.warn(`${this.constructor.name}: could not find #modal-edit in the shadowRoot`);
      }
      if (!this.modalItemDelete) {
        console.warn(`${this.constructor.name}: could not find #modal-delete in the shadowRoot`);
      }
    }

    if (!this.modalItemEdit) {
       // hide the add button
      const addBtn = this.qs('m-icon[name="add"]');
      if (addBtn) {
        addBtn.style.display = 'none';
      }
    }

    if (this.items === null) {
      await this.loadItems();
    }
    this._checkUrlForItemId();
  }

  _checkUrlForItemId() {
    const itemId = UrlParams.get(this.urlVar);
    if (itemId) {
      const item = this.items.find(p => (p._id||p.id) === itemId);
      if (item) {
        this._editItem(null, item);
      }
    }
  }

  _renderLoader() {
    return html`<div class="loader_container"><sl-progress-bar style="--height: 6px;" indeterminate></sl-progress-bar></div>`;
  }

  _renderNoItem() {
    if (this.loading || this.items?.length) return;
    return html`<div class="center">
      <br/><br/>
      ${this._tl('empty')}
      <br/><br/>
      <sl-button variant="primary" @click="${this._addItem}">${this._tl('add')}</sl-button>
    </div>`;
  }

  _addItem() {
    this.modalItemEdit.item = { ...this.itemModel };
  }

  _editItem(ev, item) {
    
    if (this.modalItemEdit?.modal?.pendingChanges) {
      this.modalItemEdit.modal.showPendingChanges();
      return;
    }

    this._log.info('_editItem', item, ev);
    if (ev?.target) {
      const tr = ev.target.closest('tr');
      if (tr) {
        this._unselectAll();
        tr.classList.add('selected');
      }
    }

    if (ev?.target?.tagName === 'M-ICON') return;

    const editedItem = merge({ ...item }, this.itemModel);
    if (!this.modalItemEdit) {
      if (ENV === 'dev') {
        console.warn(`${this.constructor.name}: you MUST implement method _renderEditComponent`);
      }
      return;
    }
    this.modalItemEdit.item = null;
    this.modalItemEdit.item = editedItem;
  }

  async _unselectAll() {
    const selectedTr = this.shadowRoot.querySelector('tr.selected');
    if (selectedTr) {
      selectedTr.classList.remove('selected');
    }
  }

  async _selectItem(itemId) {
    this._unselectAll();
    const trs = this.shadowRoot.querySelectorAll('tr');
    for (const tr of trs) {
      if (tr.getAttribute('data-id') === itemId) {
        tr.classList.add('selected');
      }
    }
  }

  async _deleteItem() {
    this.modalItemDelete.loadingButtons();
    const url = `${this.apiEndpoint}/${(this.item._id||this.item.id) || this.item[this.itemTitleField]}`;
    await Fetcher.delete(url);
    this.modalItemDelete.resetButtons();
    this.modalItemDelete.hide();
    delete this.item;
    await this.loadItems();
  }

  _deleteItemConfirm(ev, item) {
    ev.stopPropagation();
    this.modalItemDelete.querySelector('.itemTitle').textContent = this._getItemTitle(item);
    this.modalItemDelete.show();
    this.item = item;
  }

  _getItemTitle(item) {
    return Lang.lookup(item, this.itemTitleField);
  }

  _getTableHeader() {
    // you can override me
    return '';
  }


  async _duplicateItemShow(ev, item) {
    ev.target.closest('sl-dropdown').hide();
    ev.stopPropagation();
    this.modalDuplicate = this.modalDuplicate || this.shadowRoot.querySelector('#modal-duplicate');
    this.modalDuplicate.label = `${this._tl('duplicate')} ${item.name}`;
    this.modalDuplicate.show();

    this.modalDuplicate.input = this.modalDuplicate.querySelector('sl-input');
    this.modalDuplicate.input.value = `${item.name} (${this._tl('copy_of')})`;
    this.modalDuplicate.item = item;
    await Sleep(200);
    this.modalDuplicate.input.focus();
  }

  async _duplicateItem() {
    this.modalDuplicate.loadingButtons();
    const id = this.modalDuplicate.item._id || this.modalDuplicate.item.id;
    const response = await Fetcher.post(`${this.apiEndpoint}/${id}`, { name: this.modalDuplicate.input.value });
    const item = response?.data;
    this.modalDuplicate.resetButtons();
    if (item._id || item.id) {
      this.modalDuplicate.hide();
      window.dispatchEvent(new CustomEvent(this.eventUpdated, { detail: item }));
      await Sleep(200);
      this._editItem(null, item);
    }
  }


  _renderItems() {
    throw new Error(`${this.constructor.name}: you MUST implement method _renderItems`);
  }

  _renderHeader() {
    return html`
      <section-header size="small">
        ${this.loading 
          ? this._renderLoader()
          : html`
            ${this._getTableHeader()}
            ${this.modalItemEdit
              ? this.addDisabled 
                ? ''
                : html`<m-icon slot="right" size="big" name="add" @click=${this._addItem}></m-icon>`
              : ''
            }
            `
        }
      </section-header>`
  }

  _renderDeleteModal() {
    return html`
      <modal-dialog id="modal-delete" label="Confirmation demandée">
        <div>
          ${this._tl('remove_confirm')} &laquo;<span class="itemTitle"></span>&raquo;?
        </div>
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click=${this._deleteItem}>${this._tl('remove')}</sl-button>
      </modal-dialog>
    `;
  }

  _renderDuplicateModal() {
    return html`
      <modal-dialog id="modal-duplicate">
        ${this._tl('duplicate_new_name')}<br/><br/>
        <sl-input size="small" name="name"></sl-input>
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this._duplicateItem}">${this._tl('duplicate')}</sl-button>
      </modal-dialog>
    `
  }

  render() {    
    return html`
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      ${this._renderEditComponent()}
      ${this._renderDeleteModal()}
      ${this._renderDuplicateModal()}
    `;
  }

}

export default TableBasic;